declare type moment = any;
declare type IStateService = any;
import { ILogger } from "../../js/interfaces/ILogger";
import { FxpMessageService } from "../banner/FxpMessageService";
import {
  FxpConstants,
} from "../../js/common/ApplicationConstants";
import { UserInfoService } from "../../js/services/UserInfoService";
import { SYSTEM_MESSAGE_UI } from "../../js/constants/systemMessages.constants";
import { IPersonalizationService } from "../../js/interfaces/IPersonalizationService";
import { TimeZoneHelper } from "../../js/services/TimeZoneHelper";
import { ISystemMessage } from "./systemMessage.model";
import { ISystemMessagesService } from "./ISystemMessagesService";

import {
  HideSpecificLoader,
  ShowSpecificLoader,
} from "../../app/loader/loader.actions";
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { UI_LOADER_NAMES } from "../loader/UILoader.constants";
import { TelemetryConstants } from "../../js/telemetry/TelemetryConst";
import { ErrorCodes } from "../../js/constants/errorCodes";
import { ErrorSeverityLevel } from "../../js/telemetry/ErrorSeverityLevel";
import { FeatureUsageEvent } from "../../js/telemetry/FeatureUsageEvent";
import {
  ActionStatus,
  ActionType,
  ComponentType,
  EventName,
} from "@microsoftit/telemetry-extensions-npm";
declare var _: any;

type SystemMessagesModalTypes = "NONE" | "ADD" | "EDIT" | "DELETE";
type SortOrder = "ASC" | "DESC";

const SystemMessageFeature = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Administration.SystemMessage`;
const SystemMessageFeatureActions = {
  list: "ViewSystemMessages",
  add: "AddSystemMessage",
  update: "UpdateSystemMessage",
  delete: "DeleteSystemMessage",
};

export class SystemMessagesController {
  startDateModel: Date = new Date();
  startTimeModel: Date = new Date();
  endDateModel: Date = new Date();
  endTimeModel: Date = new Date();
  name = "Fxp.Controllers.SystemMessagesController";
  systemMessageCollection: Array<ISystemMessage> = [];
  systemMessage: ISystemMessage = null;
  messageCountCollection = [10, 20, 30];
  messageCount: number;
  messagePerPageCount = 10;
  pageCount: number = 1;
  currentPage: number = 1;
  validPreviousPageNumber: number = 1;
  sortOrder: SortOrder = "DESC";
  loggedInUser: string;
  modalType: SystemMessagesModalTypes = "NONE";
  businessCapabilityCollection: any;
  businessFunctionCollection: any;
  systemMessageDateStringFormat = "MM/DD/YY H:mm A";
  maxMessageLength: number = 1000;
  minDateTime: Date = new Date();
  systemMessageTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  systemMessageTimeZoneCode: string;
  sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SystemMessagesController`;
  systemMessageFeatureEvent: FeatureUsageEvent;
  startDatePopup = {
    opened: false,
  };
  endDatePopup = {
    opened: false,
  };
  startDatepickerOptions = {
    showWeeks: false,
    minDate: new Date(),
  };
  endDatepickerOptions = {
    showWeeks: false,
    minDate: this.startDateModel,
  };
  datePickerFormat = "MM-dd-yyyy";
  constructor(
    private scope: any,
    private logger: ILogger,
    private fxpMessageService: FxpMessageService,
    private TimeZoneHelper: TimeZoneHelper,
    private SystemMessagesService: ISystemMessagesService,
    private PersonalizationService: IPersonalizationService,
    private fxpGlobalStoreService: FxpGlobalStoreService,
    private UserInfoService: UserInfoService,
    public UiString: SYSTEM_MESSAGE_UI
  ) {
    let addMessageButton: any;
    scope.smCtrl = this;
    logger.renewCorrelationId();
    this.loggedInUser = UserInfoService.getLoggedInUserUpn();
    this.populateSystemMessagesCollection();
    this.cacheBusinessCapabilityL0List();
    this.systemMessageTimeZoneCode = TimeZoneHelper.getCurrentTimeZoneCode(
      this.systemMessageTimeZone
    );
    addMessageButton = $(".system-message-body .add-message");
    if (addMessageButton) {
      addMessageButton.focus();
    }
  }

  private populateSystemMessagesCollection = (): void => {
    var self = this;
    self.fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowSpecificLoader({
        name: UI_LOADER_NAMES.FXP_LOADERS
          .SYSTEM_MESSAGE_POPULATE_MESSAGE_LOADER,
        loadingText: SYSTEM_MESSAGE_UI.LOADING_TEXTS.FETCHING_MESSAGES,
      })
    );
    self.SystemMessagesService.getSystemMessagesCollectionAsync(
      self.messagePerPageCount,
      self.currentPage,
      self.sortOrder
    )
      .then(onSuccess)
      .catch(onRejected)
      .finally(() => {
        self.fxpGlobalStoreService.DispatchGlobalAction(
          "Platform",
          HideSpecificLoader({
            name: UI_LOADER_NAMES.FXP_LOADERS
              .SYSTEM_MESSAGE_POPULATE_MESSAGE_LOADER,
          })
        );
      });

    function onSuccess(response) {
      try {
        self.systemMessageCollection = response.data.systemMessages;
        self.messageCount = response.data.recordCount;
        self.pageCount = calcuatePageCount();

        _.each(self.systemMessageCollection, (item: ISystemMessage) => {
          convertMessageUtcTimeZoneToPacificTime(item);
          mapMessageTypeToDisplayText(item);
        });

        self.systemMessageFeatureEvent = new FeatureUsageEvent(
          SystemMessageFeature,
          ActionType.User,
          SystemMessageFeatureActions.list,
          EventName.PageLoad,
          ComponentType.Web
        );
        self.logger.logFeatureUsageEvent(
          self.sourceForTelemetry,
          self.systemMessageFeatureEvent
        );
      } catch (error) {
        onRejected(error);
      }
    }
    function calcuatePageCount() {
      return Math.ceil(self.messageCount / self.messagePerPageCount);
    }

    function mapMessageTypeToDisplayText(item: ISystemMessage) {
      if (item.messageType.toLocaleLowerCase() === "intermittent")
        item.messageTypeDisplayText = "Banner";
      else item.messageTypeDisplayText = "Maintenance Page";
    }
    function convertMessageUtcTimeZoneToPacificTime(item: ISystemMessage) {
      item.startTime = self.TimeZoneHelper.convertToLocal(item.startTime);
      item.endTime = self.TimeZoneHelper.convertToLocal(item.endTime);
    }
    function updateStatus(item: ISystemMessage) {
      item.isActive = item.isActive
        ? SYSTEM_MESSAGE_UI.ACTIVE
        : SYSTEM_MESSAGE_UI.EXPIRED;
    }
    function onRejected(reason: any) {
      let errorMessage = SYSTEM_MESSAGE_UI.ERRORS.CANNOT_FETCH_SYSTEM_MESSAGE;
      self.fxpMessageService.addMessage(
        errorMessage,
        FxpConstants.messageType.error
      );
      self.logger.logError(
        `${self.sourceForTelemetry}.PopulateSystemMessagesCollection`,
        errorMessage,
        ErrorCodes.SystemMessage_Read_Collection_Rejection,
        reason,
        null,
        null,
        null,
        ErrorSeverityLevel.Medium
      );
    }
  };

  private cacheBusinessCapabilityL0List = (): void => {
    var self = this;
    self.PersonalizationService.getMasterLeftNavItems().then(
      onSuccess,
      onError
    );

    function onSuccess(response) {
      try {
        self.businessCapabilityCollection = getL0Collection();
      } catch (error) {
        onError(error);
      }

      function getL0Collection() {
        let L0Collection = _.map(response?.result, L0Mapper);
        L0Collection = L0Collection.filter(function (element) {
          return element.name != "Help";
        });
        L0Collection.sort(function (obj1, obj2) {
          return obj1.name > obj2.name ? 1 : -1;
        });
        L0Collection.unshift({
          id: 0,
          name: "All",
          businessFunctionCollection: [{ id: 0, name: "All" }],
        });
        return L0Collection;
      }

      function L0Mapper(L0) {
        return {
          id: L0.id,
          name: L0.displayName,
          businessFunctionCollection: getL1Collection(L0),
        };

        function getL1Collection(L0) {
          let L1Collection = _.map(L0.children, forEachL1);
          L1Collection.unshift({ id: 0, name: "All" });
          return L1Collection;

          function forEachL1(L1) {
            return {
              id: L1.id,
              name: L1.displayName,
            };
          }
        }
      }
    }
    function onError(error) {
      let errorMessage =
        SYSTEM_MESSAGE_UI.ERRORS.CANNOT_FETCH_BBUSINESS_WORKFLOW;
      self.fxpMessageService.addMessage(
        errorMessage,
        FxpConstants.messageType.error
      );
      self.logger.logError(
        `${self.sourceForTelemetry}.CacheBusinessCapabilityL0List`,
        errorMessage,
        ErrorCodes.SystemMessage_Fetch_Business_Workflow,
        error,
        null,
        null,
        null,
        ErrorSeverityLevel.Medium
      );
    }
  };

  private getActionNameForFeatureUsage(modalType: SystemMessagesModalTypes) {
    switch (modalType) {
      case "ADD":
        return SystemMessageFeatureActions.add;
      case "EDIT":
        return SystemMessageFeatureActions.update;
      case "DELETE":
        return SystemMessageFeatureActions.delete;
      default:
        return "";
    }
  }
  showModal(
    modalType: SystemMessagesModalTypes,
    message: ISystemMessage = {} as any
  ): void {
    var self = this;
    this.logger.renewSubCorrelationId();
    self.systemMessageFeatureEvent = new FeatureUsageEvent(
      SystemMessageFeature,
      ActionType.User,
      self.getActionNameForFeatureUsage(modalType),
      EventName.ButtonClick,
      ComponentType.Web
    );
    self.logger.startFeatureUsageEvent(self.systemMessageFeatureEvent);
    self.systemMessage = setDefaultValues(message);
    self.modalType = modalType;
    updateBusinessFunctionL1Collection();

    setTimeout(function () {
      let controlToFocus = null;
      if (modalType == "ADD" || modalType == "EDIT") {
        controlToFocus = document.getElementById("addMessageClosePopup");
      }
      if (modalType == "DELETE") {
        controlToFocus = document.getElementById("deleteMessageClosePopup");
      }
      if (controlToFocus) {
        controlToFocus.focus();
      }
    }, 0);

    function setDefaultValues(message) {
      let systemMessage = Object.assign({}, message, getEmptyMessage());
      formatDates();
      mapDisplayTextToMessageType();
      return systemMessage;

      function getEmptyMessage() {
        return {
          id: "",
          messageTypeDisplayText: "Banner",
          startTime: self.TimeZoneHelper.getCurrentLocalDateTime(),
          endTime: self.TimeZoneHelper.addHoursToLocalUtcDateTime(1),
          businessCapability: [],
          businessFunction: [],
          startTimeString: "",
          endTimeString: "",
        };
      }

      function formatDates() {
        systemMessage.startTimeString = systemMessage.startTime.format(
          self.systemMessageDateStringFormat
        );
        systemMessage.endTimeString = systemMessage.endTime.format(
          self.systemMessageDateStringFormat
        );
      }

      function mapDisplayTextToMessageType() {
        if (
          systemMessage.messageTypeDisplayText.toLocaleLowerCase() === "banner"
        )
          systemMessage.messageType = "Intermittent";
        else systemMessage.messageType = "SystemDown";
      }
    }

    function updateBusinessFunctionL1Collection() {
      if (self.systemMessage.businessCapability.length > 0) {
        var businessCapability: any = _.find(
          self.businessCapabilityCollection,
          (item) => {
            return item.id == self.systemMessage.businessCapability[0].id;
          }
        );
        if (
          businessCapability &&
          businessCapability.businessFunctionCollection
        ) {
          self.businessFunctionCollection =
            businessCapability.businessFunctionCollection;
        }
      }
    }
  }
  hideModal(): void {
    this.modalType = "NONE";
    this.systemMessage = null;
  }
  addSystemMessage = (): void => {
    var self = this;

    self.systemMessage.createdBy = self.systemMessage.lastModifiedBy =
      this.loggedInUser;
    delete self.systemMessage.businessCapability[0].businessFunctionCollection;
    const request = { ...this.systemMessage };
    request.startTime = self.TimeZoneHelper.convertToUtc(
      this.timeBuilder(this.startDateModel, this.startTimeModel)
    );
    request.endTime = self.TimeZoneHelper.convertToUtc(
      this.timeBuilder(this.endDateModel, this.endTimeModel)
    );
    self.fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowSpecificLoader({
        name: UI_LOADER_NAMES.FXP_LOADERS.SYSTEM_MESSAGE_ADD_MESSAGE_LOADER,
        loadingText: SYSTEM_MESSAGE_UI.LOADING_TEXTS.ADDING_MESSAGE,
      })
    );
    self.SystemMessagesService.saveSystemMessageAsync(request)
      .then(successCallback, onRejected)
      .finally(popupDestroy);

    function successCallback(response: any) {
      let successMessage = SYSTEM_MESSAGE_UI.SUCCESS.SYSTEM_MESSAGE_ADDED;
      setTimeout(function () {
        self.fxpMessageService.addMessage(
          successMessage,
          FxpConstants.messageType.success
        );
      });
      let properties = self.logger.createPropertyBag();
      properties.addToBag(
        "Message",
        `System Messages successfully added with id: ${response.data.id}`
      );
      self.populateSystemMessagesCollection();
      self.systemMessageFeatureEvent.ActionStatus = ActionStatus.Succeeded;
      self.logger.endFeatureUsageEvent(
        `${self.sourceForTelemetry}.addSystemMessage.successCallback`,
        self.systemMessageFeatureEvent,
        properties
      );
    }

    function onRejected(reason: any) {
      let errorMessage = SYSTEM_MESSAGE_UI.ERRORS.CANNOT_ADD_SYSTEM_MESSAGE;
      self.fxpMessageService.addMessage(
        errorMessage,
        FxpConstants.messageType.error
      );
      self.logger.logError(
        `${self.sourceForTelemetry}.AddSystemMessage`,
        errorMessage,
        ErrorCodes.SystemMessage_Save_Failure,
        reason,
        null,
        null,
        null,
        ErrorSeverityLevel.Medium
      );
      self.systemMessageFeatureEvent.ActionStatus = ActionStatus.Failed;
      self.logger.endFeatureUsageEvent(
        `${self.sourceForTelemetry}.addSystemMessage.successCallback`,
        self.systemMessageFeatureEvent
      );
    }
    function popupDestroy() {
      self.hideModal.bind(self)();
      self.fxpGlobalStoreService.DispatchGlobalAction(
        "Platform",
        HideSpecificLoader({
          name: UI_LOADER_NAMES.FXP_LOADERS.SYSTEM_MESSAGE_ADD_MESSAGE_LOADER,
        })
      );
    }
  };
  updateSystemMessage = (): void => {
    var self = this;
    self.systemMessage.lastModifiedBy = this.loggedInUser;

    const request = { ...self.systemMessage };
    delete request.businessCapability[0].businessFunctionCollection;
    delete request.endTimeString;
    delete request.startTimeString;

    request.startTime = self.TimeZoneHelper.convertToUtc(
      this.timeBuilder(this.startDateModel, this.startTimeModel)
    );
    request.endTime = self.TimeZoneHelper.convertToUtc(
      this.timeBuilder(this.endDateModel, this.endTimeModel)
    );

    self.fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowSpecificLoader({
        name: UI_LOADER_NAMES.FXP_LOADERS.SYSTEM_MESSAGE_UPDATE_MESSAGE_LOADER,
        loadingText: SYSTEM_MESSAGE_UI.LOADING_TEXTS.UPDATING_MESSAGE,
      })
    );
    self.SystemMessagesService.saveSystemMessageAsync(request)
      .then(successCallback, onRejected)
      .finally(popupDestroy);

    function successCallback(response: any) {
      let successMessage = SYSTEM_MESSAGE_UI.SUCCESS.SYSTEM_MESSAGE_UPDATED;
      setTimeout(function () {
        self.fxpMessageService.addMessage(
          successMessage,
          FxpConstants.messageType.success
        );
      });
      let properties = self.logger.createPropertyBag();
      properties.addToBag(
        "Message",
        `System Message with id: ${response.data.id} updated`
      );
      self.logger.logInformation(
        `${self.sourceForTelemetry}.UpdateSystemMessage`,
        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SystemMessageUpdated`
      );
      self.populateSystemMessagesCollection();
      self.systemMessageFeatureEvent.ActionStatus = ActionStatus.Succeeded;
      self.logger.endFeatureUsageEvent(
        `${self.sourceForTelemetry}.updateSystemMessage.successCallback`,
        self.systemMessageFeatureEvent,
        properties
      );
    }
    function onRejected(reason: any) {
      let errorMessage = SYSTEM_MESSAGE_UI.ERRORS.CANNOT_UPDATE_SYSTEM_MESSAGE;
      self.fxpMessageService.addMessage(
        errorMessage,
        FxpConstants.messageType.error
      );
      self.logger.logError(
        `${self.sourceForTelemetry}.UpdateSystemMessage`,
        errorMessage,
        ErrorCodes.SystemMessage_Update_Failure,
        reason,
        null,
        null,
        null,
        ErrorSeverityLevel.Medium
      );
      self.systemMessageFeatureEvent.ActionStatus = ActionStatus.Succeeded;
      self.logger.endFeatureUsageEvent(
        `${self.sourceForTelemetry}.updateSystemMessage.onRejected`,
        self.systemMessageFeatureEvent
      );
    }

    function popupDestroy() {
      self.hideModal.bind(self)();
      self.fxpGlobalStoreService.DispatchGlobalAction(
        "Platform",
        HideSpecificLoader({
          name: UI_LOADER_NAMES.FXP_LOADERS
            .SYSTEM_MESSAGE_UPDATE_MESSAGE_LOADER,
        })
      );
    }
  };
  deleteSystemMessage = (): void => {
    var self = this;
    self.fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowSpecificLoader({
        name: UI_LOADER_NAMES.FXP_LOADERS.SYSTEM_MESSAGE_DELETE_MESSAGE_LOADER,
        loadingText: SYSTEM_MESSAGE_UI.LOADING_TEXTS.DELETING_MESSAGE,
      })
    );
    self.SystemMessagesService.deleteSystemMessageAsync(self.systemMessage.id)
      .then(successCallback, onRejected)
      .finally(popupDestroy);

    function successCallback() {
      let successMessage = SYSTEM_MESSAGE_UI.SUCCESS.SYSTEM_MESSAGE_DELETED;
      setTimeout(function () {
        self.fxpMessageService.addMessage(
          successMessage,
          FxpConstants.messageType.success
        );
      });
      let properties = self.logger.createPropertyBag();
      properties.addToBag(
        "Message",
        `System Message with ${self.systemMessage.id} Deleted Successfuly`
      );
      self.logger.logInformation(
        `${self.sourceForTelemetry}.DeleteSystemMessage`,
        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SystemMessageDeleted`
      );
      if (areAllMessagesOfCurrentPageDeleted()) {
        self.currentPage--;
        self.changePage();
      }
      self.populateSystemMessagesCollection();

      self.systemMessageFeatureEvent.ActionStatus = ActionStatus.Succeeded;
      self.logger.endFeatureUsageEvent(
        `${self.sourceForTelemetry}.deleteSystemMessage.successCallback`,
        self.systemMessageFeatureEvent
      );

      function areAllMessagesOfCurrentPageDeleted() {
        return (
          self.currentPage >
          Math.ceil((self.messageCount - 1) / self.messagePerPageCount) &&
          self.currentPage > 1
        );
      }
    }
    function onRejected(reason: any) {
      let errorMessage = SYSTEM_MESSAGE_UI.ERRORS.CANNOT_DELETE_SYSTEM_MESSAGE;
      self.fxpMessageService.addMessage(
        errorMessage,
        FxpConstants.messageType.error
      );
      self.logger.logError(
        `${self.sourceForTelemetry}.DeleteSystemMessage`,
        errorMessage,
        ErrorCodes.SystemMessage_Delete_Failure,
        reason,
        null,
        null,
        null,
        ErrorSeverityLevel.Medium
      );
      self.systemMessageFeatureEvent.ActionStatus = ActionStatus.Failed;
      self.logger.endFeatureUsageEvent(
        `${self.sourceForTelemetry}.deleteSystemMessage.onRejected`,
        self.systemMessageFeatureEvent
      );
    }

    function popupDestroy() {
      self.hideModal.bind(self)();
      self.fxpGlobalStoreService.DispatchGlobalAction(
        "Platform",
        HideSpecificLoader({
          name: UI_LOADER_NAMES.FXP_LOADERS
            .SYSTEM_MESSAGE_DELETE_MESSAGE_LOADER,
        })
      );
    }
  };
  updateBusinessFunctionCollection = (): void => {
    if (this.systemMessage.businessCapability[0]) {
      this.businessFunctionCollection = [...this.systemMessage.businessCapability[0].businessFunctionCollection];
      this.systemMessage.businessFunction[0] =
        this.businessFunctionCollection[0];
    } else {
      this.businessFunctionCollection = this.systemMessage.businessFunction[0] =
        [];
    }
  };
  updateMessagePerPageCount = (): void => {
    this.currentPage = this.validPreviousPageNumber = 1;
    this.populateSystemMessagesCollection();
  };

  changePageOnKeydown(position: string) {
    if (
      this.currentPage !== undefined &&
      this.currentPage !== null &&
      this.currentPage > 0
    ) {
      this.changePage(false, position);
    }
  }
  resetPageNumberOnBlur() {
    if (this.currentPage != this.validPreviousPageNumber) {
      this.currentPage = this.validPreviousPageNumber;
    }
  }

  changePage(resetFocus: boolean = true, position: string = "header") {
    if (this.currentPage > this.pageCount) {
      this.currentPage = this.pageCount;
    }
    this.validPreviousPageNumber = this.currentPage;
    this.populateSystemMessagesCollection();

    if (!resetFocus) return;

    if (this.currentPage == 1) {
      setTimeout(() => {
        $("#pagination_nextpage_" + position).focus();
      }, 0);
    }
    if (this.currentPage == this.pageCount) {
      setTimeout(() => {
        $("#pagination_previouspage_" + position).focus();
      }, 0);
    }
  }

  loadNextPage = (position: string) => {
    this.currentPage = this.currentPage + 1;
    this.changePage(true, position);
  };

  loadPrevPage = (position: string) => {
    this.currentPage = this.currentPage - 1;
    this.changePage(true, position);
  };
  toggleSortOrder() {
    this.sortOrder = this.sortOrder == "DESC" ? "ASC" : "DESC";
    this.populateSystemMessagesCollection();
  }
  getStatusText(status) {
    return status ? SYSTEM_MESSAGE_UI.ACTIVE : SYSTEM_MESSAGE_UI.EXPIRED;
  }

  keyPressOnAddMessage(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.hideModal();
      event.stopPropagation();
    }
  }

  startDateOpen() {
    this.startDatePopup.opened = true;
  }
  endDateOpen() {
    this.endDatePopup.opened = true;
  }
  timeBuilder(date: Date, time: Date): any {
    var dateValue = date.toISOString().split("T")[0];
    var timeValue = time.toISOString().split("T")[1];
    return dateValue + "T" + timeValue;
  }
  updateEndDateMinValue() {
    this.endDatepickerOptions.minDate = this.startDateModel;
    this.endDateModel = this.startDateModel;
  }

  timepickerSpinnersFocusable() {
    var spinners = document.querySelectorAll(".fxp-timepicker a");
    spinners.forEach((spinner) => {
      spinner.setAttribute("href", "");
    });
  }
}
